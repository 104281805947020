<template>
   <section class="slider__area grey-bg">
      <div class="slider__inner slider-active">
         <div v-for="(banner, index) in clienteData.banners" :key="index" class="single-slider w-img">
            <img :src="banner == null ? '' : banner" alt="slider" @error="imageError">
         </div>
         <div v-if="clienteData.banners == null || clienteData.banners.length == 0" class="single-slider w-img">
            <img src="@/assets/cliente/img/errorBanner.png" alt="slider">
         </div>
      </div>
   </section>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import errorBanner from '@/assets/cliente/img/errorBanner.png'

export default {
   name: 'Slide',
   computed: {
		... mapState({
			clienteData: state => state.clienteData
		})
	},
   watch: {
      'clienteData.banners' : function (val) {
         if (val != null && val.length > 0) {
            this.configSlides()
         }
      }
   },
   methods: {
      imageError : function (e) {
         e.target.src = errorBanner;
      },
      doAnimations : function (elements) {
         var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            
         elements.each(function () {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
               'animation-delay': $animationDelay,
               '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function () {
               $this.removeClass($animationType);
            });
         });
      },
      configSlides : function () {
         let ref = this;

         if (this.clienteData.banners != null && this.clienteData.banners.length > 0) {
            Vue.nextTick(function() {
               try {
                  $('.slider-active').on('init', function () {
                     var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
                     ref.doAnimations($firstAnimatingElements)
                  });
         
                  $('.slider-active').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
                     var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
                     ref.doAnimations($animatingElements)
                  });
         
                  $('.slider-active').slick({
                     autoplay: true,
                     autoplaySpeed: 8000,
                     dots: true,
                     fade: false,
                     arrows: false,
                     prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
                     nextArrow: '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
                     responsive: [{
                        breakpoint: 767,
                        settings: {
                           dots: false,
                           arrows: false
                        }
                     }]
                  });
               } catch ({ name, message }) {
                  console.warn("Slide: "+ name +" - "+ message)
               }
            }.bind(this));
         }
      }
   },
   mounted() {
      this.configSlides()
   }
}

</script>